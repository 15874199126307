@font-face {
  font-family: "Encode Sans-Regular";
  src: url("#{$ti-font-path}/EncodeSans-Regular.ttf?-fvbane") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Encode Sans-Medium";
  src: url("#{$ti-font-path}/EncodeSans-Medium.ttf?-fvbane") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Encode Sans-SemiBold";
  src: url("#{$ti-font-path}/EncodeSans-SemiBold.ttf?-fvbane") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.dashboard-col {
  border-radius: 0px;
  text-align: center;
  padding: 12px 8px;
  min-height: 88px;
  justify-content: center;
  h5 {
    font-size: 19px;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 3px;
  }
}

.dateRange-select-box {
  padding: 4px 6px;
  width: 210px;
}

.c-pointer {
  cursor: pointer;
}

.radio-btn {
  position: relative;
  top: 1px;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: unset !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.css-1hb7zxy-IndicatorsContainer {
  background-color: $theme-default;
}

.css-yk16xz-control {
  border-radius: 0px;
}

.dateRange-select-box {
  border-radius: 0px;
  border: 1px solid #ced4da;
  padding-top: 7px;
  padding-bottom: 7px;
}

.float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 30px;
  right: 30px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #999;
  z-index: 8;
}

.my-float {
  margin-top: 10px;
  margin-left: 1px;
  color: white !important;
}

.orderlist-tag {
  color: white;
  padding: 5px;
}

.border-box {
  border: 1px solid #dddddd;
}

.content-wrapper {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
}

.top-agent-filter {
  flex-wrap: wrap;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  div {
    padding-right: 10px;
  }
}

.table-filter {
  .col {
    flex-grow: 0;
    margin-bottom: 5px;
    button {
      position: absolute;
      bottom: 0;
    }
  }
}

.combo-details {
  margin-left: 0px;
  margin-right: 0px;
  .combo-wrapper {
    display: inline-flex;
    .combo-product-box {
      margin: 0 0 10px;
      width: 180px;
      .img-wrapper {
        height: 180px;
        width: 100%;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 3px;
        }
      }
      .product-detail {
        margin-top: 5px;
        text-align: center;
      }
    }
  }
  i {
    font-size: 20px;
    padding: 0 10px;
    margin-top: 80px;
  }
  .combo-wrapper:last-child {
    i {
      display: none;
    }
  }
}

.combo-list {
  margin-top: 5px;
  p {
    font-weight: 500;
    margin-bottom: 2px;
    padding-left: 10px;
    line-height: 1.4;
  }
  &.order {
    margin-top: 2px;
    p {
      font-size: 13px;
      margin-bottom: 0px;
    }
  }
  &.cart {
    text-align: left;
    p {
      margin-top: 2px;
      font-size: 13px;
      margin-bottom: 0px;
    }
  }
}

.order-list {
  div:not(:first-child) {
    text-align: right;
  }
}

.filter-tabs {
  display: inline-flex;
  border-bottom: 1px solid #ddd;
  width: 100%;
  margin: 5px 0;
  overflow-x: auto;
  overflow-y: hidden;
  div {
    width: auto;
    text-align: center;
    padding: 10px 25px;
    white-space: pre;
    flex-wrap: nowrap;
    &.active {
      border-bottom: 2px solid $theme-default;
      margin-bottom: -1px;
      color: $theme-default;
    }
  }
  @media (max-width: 490px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.password-icon{
  height:20px;
  margin: 0 5px;
  position: absolute;
  right: 15px;
  margin-top: 40px;
  cursor: pointer;
  &.login{
    right: 35px;
  }
}
